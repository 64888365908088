// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./address.scss"></require>
  <section id="address" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1>Select Delivery Address</h1>
    </div>

    <form id="address_form" class="flex-column">
        <div class="address__name item">
          <label for="name">Full Name</label>
          <input type="text" required>
        </div>
        <div class="address__address_1 item">
          <label for="address_1">Address Line 1</label>
          <input type="text" required>
        </div>
        <div class="address__address_2 item">
          <label for="address_2">Address Line 2(optional)</label>
          <input type="text">
        </div>
        <div class="address__city item">
          <label for="city">City</label>
          <input type="text" required>
        </div>
        <div class="address__country item">
          <label for="country">Country</label>
          <input type="text" required>
        </div>
        <div class="address__ZIPPostcode item">
          <label for="country">ZIP/Postcode</label>
          <input type="text" required>
        </div>
    </form>

    <div class="flex-grow"></div>
    <button class="btn btn-primary">
      <span>Next</span>
    </button>
    <div class="spacer-default"></div>

  </div>
    

  </section>
</template>
`;
// Exports
export default code;